//******************************************************************************
  * Notifications used in login block
  ******************************************************************************
.mail-notification
  background-color: $brand-orange
  color: $white
  min-height: 90px
  padding: (2 * $grid-gutter-width) $grid-gutter-width
  position: relative
  width: 100%

  span[class*="ipi-alert-"]
    font-size: 1.2rem
    left: $grid-gutter-width
    position: absolute
    top: 50%
    transform: translateY(-50%)

  .close
    color: $white
    font-size: 1.8rem
    height: 24px
    opacity: $opacity-default
    outline: none
    position: absolute
    right: $grid-gutter-width
    top: 50%
    transform: translateY(-50%)
    &:hover
      opacity: $opacity-on-hover

  p
    font-weight: 100
    left: $grid-gutter-width
    line-height: $line-height-base
    margin: 0
    padding: 0 (3 * $grid-gutter-width) 0 (2.5 * $grid-gutter-width)
    position: absolute
    right: $grid-gutter-width
    top: 50%
    transform: translateY(-50%)
    a
      color: $white
      text-decoration: underline
