// TODO: Move relevant parts to mq_index.sass
// XS (x<480px)
@include xs
  #side-menu
    width: calc(100% - 60px)
    max-width: 300px
    min-width: 140px
    .side-wrapper
      width: auto

// MS (480-719px)
@include ms
  .sidebar
    margin-left: ($padding-screen-ms + ($grid-gutter-width*0.5))
