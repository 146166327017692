/*
Customization for default Bootstrap grid.
Adding extra breakpoints and changing default ones.
XS  300+    (BS default x<750>x)
MS  480+    (extra breakpoint)
SM  720+    (BS default 750+)
MD  960+    (BS default 970+)
LG  1260+   (BS default 1200+)
XL  1440+   (extra breakpoint)
XXL 1920+   (extra breakpoint)
*/

@use "sass:math";

.container {
    //@include container-fixed;

    @media (min-width: $screen-xs-min) {
        width: $container-xs;
    }

    @media (min-width: $screen-ms-min) {
        width: $container-ms;
    }

    @media (min-width: $screen-sm-min) {
        width: $container-sm;
    }

    @media (min-width: $screen-md-min) {
        width: $container-md;
    }

    @media (min-width: $screen-lg-min) {
        width: $container-lg;
        max-width: $container-lg;
    }

    @media (min-width: $screen-xl-min) {
        width: $container-xl;
        max-width: $container-xl;
    }

    @media (min-width: $screen-xxl-min) {
        width: $container-xxl;
        max-width: $container-xxl;;
    }
}

//Fixing hidden-lg for xl and xxl
@media (min-width: $screen-xl-min){
    .hidden-lg {
        display: block !important
    }
}

//Adding extra grid brakepoints
////////////////////////
// MS brakepoint (480+)
////////////////////////
// Common styles (see make-grid-columns() in bootstrap/mixins/_grid-framework.less)
.col-ms-1,
.col-ms-2,
.col-ms-3,
.col-ms-4,
.col-ms-5,
.col-ms-6,
.col-ms-7,
.col-ms-8,
.col-ms-9,
.col-ms-10,
.col-ms-11,
.col-ms-12 {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
    // Inner gutter via padding
    padding-left: ($grid-gutter-width * 0.5);
    padding-right: ($grid-gutter-width * 0.5);
}

// Misc. class adjustments for col-ms
//@media (min-width: $screen-ms) and (max-width: $screen-ms-max) {
//    .hidden-xs {
//        display: block !important;
//    }
//}

//// col-ms grid
//@media (min-width: $screen-ms-min) {
//    @include make-grid(ms);
//}
//@media (min-width: $screen-sm-min) {
//    @include make-grid(sm);
//}
//@media (min-width: $screen-md-min) {
//    @include make-grid(md);
//}
//@media (min-width: $screen-lg-min) {
//    @include make-grid(lg);
//}

// Visibility utilities
//@include responsive-invisibility('.visible-xs');
//@include responsive-invisibility('.visible-ms');

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-ms-block,
.visible-ms-inline,
.visible-ms-inline-block {
    display: none !important;
}

//@media (max-width: $screen-xs-max-new) {
//    @include responsive-visibility('.visible-xs');
//}
.visible-xs-block {
    @media (max-width: $screen-xs-max-new) {
        display: block !important;
    }
}
.visible-xs-inline {
    @media (max-width: $screen-xs-max-new) {
        display: inline !important;
    }
}
.visible-xs-inline-block {
    @media (max-width: $screen-xs-max-new) {
        display: inline-block !important;
    }
}

//@media (min-width: $screen-ms-min) and (max-width: $screen-ms-max) {
//    @include responsive-visibility('.visible-ms');
//}
.visible-ms-block {
    @media (min-width: $screen-ms-min) and (max-width: $screen-ms-max) {
        display: block !important;
    }
}
.visible-ms-inline {
    @media (min-width: $screen-ms-min) and (max-width: $screen-ms-max) {
        display: inline !important;
    }
}
.visible-ms-inline-block {
    @media (min-width: $screen-ms-min) and (max-width: $screen-ms-max) {
        display: inline-block !important;
    }
}

//@media (max-width: $screen-xs-max-new) {
//    @include responsive-invisibility('.hidden-xs');
//}
//
//@media (min-width: $screen-ms-min) and (max-width: $screen-ms-max) {
//    @include responsive-invisibility('.hidden-ms');
//}

///////////////////////////
//// XL brakepoint (1440+)
//////////////////////////
//@media (min-width: $screen-xl-min) {
//    @include make-grid(xl);
//}

// Generate the xlarge columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left:  ($gutter * 0.5);
    padding-right: ($gutter * 0.5);

    @media (min-width: $screen-xl-min) {
        float: left;
        width: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xl-column-offset($columns) {
    @media (min-width: $screen-xl-min) {
        margin-left: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xl-column-push($columns) {
    @media (min-width: $screen-xl-min) {
        left: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xl-column-pull($columns) {
    @media (min-width: $screen-xl-min) {
        right: percentage(math.div($columns, $grid-columns));
    }
}

//@include responsive-invisibility('.visible-xl');

.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
    display: none !important;
}

.visible-lg-block {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        display: block !important;
    }
}
.visible-lg-inline {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        display: inline !important;
    }
}
.visible-lg-inline-block {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        display: inline-block !important;
    }
}


//@media (min-width: $screen-xl-min) {
//    @include responsive-visibility('.visible-xl');
//}
.visible-xl-block {
    @media (min-width: $screen-xl-min) {
        display: block !important;
    }
}
.visible-xl-inline {
    @media (min-width: $screen-xl-min) {
        display: inline !important;
    }
}
.visible-xl-inline-block {
    @media (min-width: $screen-xl-min) {
        display: inline-block !important;
    }
}


//@media (min-width: $screen-lg-min) and (max-width: $screen-lg-max){
//    @include responsive-invisibility('.hidden-lg');
//}
//
//@media (min-width: $screen-xl-min) and (max-width: $screen-xl-max){
//    @include responsive-invisibility('.hidden-xl');
//}

///////////////////////////
//// XXL brakepoint (1920+)
//////////////////////////
//@media (min-width: $screen-xxl-min) {
//    @include make-grid(xxl);
//}

// Generate the xlarge columns
@mixin make-xxl-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left:  ($gutter * 0.5);
    padding-right: ($gutter * 0.5);

    @media (min-width: $screen-xxl-min) {
        float: left;
        width: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xxl-column-offset($columns) {
    @media (min-width: $screen-xxl-min) {
        margin-left: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xxl-column-push($columns) {
    @media (min-width: $screen-xxl-min) {
        left: percentage(math.div($columns, $grid-columns));
    }
}
@mixin make-xxl-column-pull($columns) {
    @media (min-width: $screen-xxl-min) {
        right: percentage(math.div($columns, $grid-columns));
    }
}

//@include responsive-invisibility('.visible-xxl');

.visible-xxl-block,
.visible-xxl-inline,
.visible-xxl-inline-block {
    display: none !important;
}

.visible-xl-block {
    @media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
        display: block !important;
    }
}
.visible-xl-inline {
    @media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
        display: inline !important;
    }
}
.visible-xl-inline-block {
    @media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
        display: inline-block !important;
    }
}

//@media (min-width: $screen-xxl-min) {
//    @include responsive-visibility('.visible-xxl');
//}
.visible-xxl-block {
    @media (min-width: $screen-xxl-min) {
        display: block !important;
    }
}
.visible-xxl-inline {
    @media (min-width: $screen-xxl-min) {
        display: inline !important;
    }
}
.visible-xxl-inline-block {
    @media (min-width: $screen-xxl-min) {
        display: inline-block !important;
    }
}

//@media (min-width: $screen-xxl-min){
//    @include responsive-invisibility('.hidden-xxl');
//}
