#flash-messages
  align-self: flex-end
  margin-top: auto
  width: 100%

.login-block, .logged-in
  background-color: transparent
  position: relative
  //&::before
  //  border: 14px solid transparent
  //  border-color: transparent transparent $brand-gray transparent
  //  content: ""
  //  display: inline-block
  //  left: 65px
  //  position: absolute
  //  top: -27px
  //  z-index: 1000

  .block-content
    background-color: $brand-gray
    display: flex
    flex-direction: column

  .register
    padding: $grid-gutter-width + 5
    height: $widget-header
    //.ico
    //  color: $brand-red
    //  &.ipi-envelope
    //    display: inline-block
    //    width: 14px

  .login
    margin: 12px 0 0 0

    &__form
      display: flex
      justify-content: center
      margin: 0 $grid-gutter-width
      white-space: nowrap

    &__form-fieldset
      display: table
      border-spacing: $grid-gutter-width

    label, .tc
      display: table-cell
      border-spacing: 0
      position: relative
      min-width: 24px
      vertical-align: top
      height: 1px
      white-space: nowrap

      &.btn-login
        padding-left: calc($grid-gutter-width / 2)
        .ipi-lock
          display: inline-block
          width: 14px

    .tb
      display: table
      border-spacing: 0
      width: 100%

    .form-group
      display: table-row

    .form-control
      display: table-cell

    .recovery
      max-width: 170px
      a
        padding: 0 5px
        text-decoration: underline

    .btn-primary
      width: 100%
      background-color: $brand-red
      font-size: 0.875rem

  .login-bottom-block
    align-self: flex-end
    background-color: $brand-dark
    margin-top: auto
    order: 2
    width: 100%
    .prod-go
      display: inline-block
      width: 24px

    &.signup-bottom-block
      background-color: $brand-red
      .ipi-envelope
        font-size: 24px
        margin: 0 5px 0 12px

    &.go_domain_or_premium_user
      padding: 15px 8px
      color: $white
      a
        color: $white
        text-decoration: underline
        padding: 0
      strong
        font-size: 1.1rem
        font-weight: 600
      .ico
        font-size: 1.3rem
        margin-top: -4px
      p
        padding: 0 0 0 22px
        font-weight: 100
        line-height: 14px

    > a
      padding: 20px
      display: block
      width: 100%
      height: 100%
      line-height: 50px
      color: $white
      font-size: 0.9rem
      font-weight: 500
      text-decoration: none
      &:hover
        text-decoration: none
      p
        font-weight: 100
        font-size: 0.875rem
        float: right
        width: 145px
        text-align: right
        line-height: 16px
        margin: 7px 0

    > p
      >a
        text-decoration: none

    .prod-mail_plus
      font-size: 30px
      padding: 0

.login-block
  height: auto

  .block-content
    display: flex
    flex-direction: column
    height: $main-block-height

// For registered users
.logged-in
  .block-content
    height: $main-block-height * 2 + $grid-gutter-height

// Social login buttons
.social-login
  &.compact-alt-login // PORTAL-1144
    .btn:not(:last-child)
      margin-bottom: 5px !important

  align-self: flex-start
  display: flex
  margin-top: -46px
  margin-bottom: 4px

  &__btn-group
    display: flex
    flex-direction: column

  .btn:not(:last-child)
    margin-bottom: $grid-gutter-width

  .btn
    padding: 0.375rem 0.7rem
    .ico
      display: inline-block
      width: 14px
  // Apple button
  $apple-color: #fafafa
  .btn-ap
    color: $apple-color
    +bgc(invert($apple-color))
    &:hover
      background-color: $apple-color
      .ipi-apple
        color: invert($apple-color)
  // Facebook button
  .btn-fb
    background-color: $facebook-color
    &:hover
      background-color: darken($facebook-color, 10%)
  // Google button
  .btn-gg
    background-color: $google-color
    &:hover
      background-color: darken($google-color, 10%)
  // Twitter button
  .btn-tw
    background-color: $twitter-color
    &:hover
      background-color: darken($twitter-color, 10%)

  //todo webauthn
  .separator
    align-items: center
    display: flex
    flex-direction: column
    margin-right: $grid-gutter-width
    i
      color: $gray-icon
      font-style: normal
      flex-grow: 0

    &::before, &::after
      border-right: 1px solid $gray-icon
      display: block
      content: ''
      flex-grow: 1
      width: 0
