@font-face {
	font-family: "meteo-icons";
	font-display: block;
	src: url("../fonts/icons/meteo-icons.woff2?bcc6ce15bf24fb078e7caa12cdb162fa") format("woff2");
}

.ico-meteo {
	line-height: 1;
}

.ico-meteo:before {
	font-family: meteo-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.imi-cloud:before {
	content: "\f101";
}
.imi-drizzle:before {
	content: "\f102";
}
.imi-drizzlesun-moon:before {
	content: "\f103";
}
.imi-drizzlesun:before {
	content: "\f104";
}
.imi-drizzlethunder:before {
	content: "\f105";
}
.imi-drizzlethundersun-moon:before {
	content: "\f106";
}
.imi-drizzlethundersun:before {
	content: "\f107";
}
.imi-fog:before {
	content: "\f108";
}
.imi-heavysleet:before {
	content: "\f109";
}
.imi-heavysleetsun-moon:before {
	content: "\f10a";
}
.imi-heavysleetsun:before {
	content: "\f10b";
}
.imi-heavysleetthunder:before {
	content: "\f10c";
}
.imi-heavysleetthundersun-moon:before {
	content: "\f10d";
}
.imi-heavysleetthundersun:before {
	content: "\f10e";
}
.imi-heavysnow:before {
	content: "\f10f";
}
.imi-heavysnowsun-moon:before {
	content: "\f110";
}
.imi-heavysnowsun:before {
	content: "\f111";
}
.imi-heavysnowthunder:before {
	content: "\f112";
}
.imi-heavysnowthundersun-moon:before {
	content: "\f113";
}
.imi-heavysnowthundersun:before {
	content: "\f114";
}
.imi-lightcloud-moon:before {
	content: "\f115";
}
.imi-lightcloud:before {
	content: "\f116";
}
.imi-lightrain:before {
	content: "\f117";
}
.imi-lightrainsun-moon:before {
	content: "\f118";
}
.imi-lightrainsun:before {
	content: "\f119";
}
.imi-lightrainthunder:before {
	content: "\f11a";
}
.imi-lightrainthundersun-moon:before {
	content: "\f11b";
}
.imi-lightrainthundersun:before {
	content: "\f11c";
}
.imi-lightsleet:before {
	content: "\f11d";
}
.imi-lightsleetsun-moon:before {
	content: "\f11e";
}
.imi-lightsleetsun:before {
	content: "\f11f";
}
.imi-lightsleetthunder:before {
	content: "\f120";
}
.imi-lightsleetthundersun-moon:before {
	content: "\f121";
}
.imi-lightsleetthundersun:before {
	content: "\f122";
}
.imi-lightsnow:before {
	content: "\f123";
}
.imi-lightsnowsun-moon:before {
	content: "\f124";
}
.imi-lightsnowsun:before {
	content: "\f125";
}
.imi-lightsnowthunder:before {
	content: "\f126";
}
.imi-lightsnowthundersun-moon:before {
	content: "\f127";
}
.imi-lightsnowthundersun:before {
	content: "\f128";
}
.imi-partlycloud-moon:before {
	content: "\f129";
}
.imi-partlycloud:before {
	content: "\f12a";
}
.imi-rain:before {
	content: "\f12b";
}
.imi-rainsun-moon:before {
	content: "\f12c";
}
.imi-rainsun:before {
	content: "\f12d";
}
.imi-rainthunder:before {
	content: "\f12e";
}
.imi-rainthundersun-moon:before {
	content: "\f12f";
}
.imi-rainthundersun:before {
	content: "\f130";
}
.imi-sleet:before {
	content: "\f131";
}
.imi-sleetsun-moon:before {
	content: "\f132";
}
.imi-sleetsun:before {
	content: "\f133";
}
.imi-sleetsunthunder-moon:before {
	content: "\f134";
}
.imi-sleetsunthunder:before {
	content: "\f135";
}
.imi-sleetthunder:before {
	content: "\f136";
}
.imi-snow:before {
	content: "\f137";
}
.imi-snowsun-moon:before {
	content: "\f138";
}
.imi-snowsun:before {
	content: "\f139";
}
.imi-snowsunthunder-moon:before {
	content: "\f13a";
}
.imi-snowsunthunder:before {
	content: "\f13b";
}
.imi-snowthunder:before {
	content: "\f13c";
}
.imi-sun-moon:before {
	content: "\f13d";
}
.imi-sun:before {
	content: "\f13e";
}
