//News
.main-news
  position: relative
  overflow: hidden
  background-size: cover
  .main_news_text_wrapper
    position: absolute
    color: $white
    padding-left: 15px
    padding-bottom: 20px
    bottom: 0
    height: 100px
    height: calc(#{$main-block-height} - #{$login-input-height} - #{$widget-header} - #{($grid-gutter-height*2)})
    background: $brand-dark //rgba(84, 106, 123, 0.8)
    width: 100%
    h3
      margin-top: 10px
      margin-bottom: 2px
      font-size: 21px
    a
      color: $white
    p
      font-size: 1.2em

  .news-logo
    img
      margin: 5px
      //height: 40px
  .news-content
    h4
      font-weight: 600
      font-size: 1.5rem
      line-height: 25px
      color: $white
      margin: 0
      text-shadow: 1px 1px 1px #555
      position: absolute
      bottom: 60px
      padding: 10px 20px
  a:first-of-type
    vertical-align: bottom
    text-decoration: none
  .news-logo
    position: absolute
    top: 0
  .news-select
    background-color: $brand-dark
    width: 100%
    height: 60px
    line-height: 10px
    position: absolute
    bottom: 0
    left: 0
    ul
      margin: 20px 50px 20px 20px
      padding: 0
      overflow: hidden
      float: left
      max-height: 20px
      li
        display: inline-block
        padding-right: 10px
        &.active>a
          background-color: $brand-red
        a
          color: $white
          font-size: 0.7rem
          text-transform: uppercase
          font-weight: 600
          padding: 1px 4px 2px 4px
          line-height: 20px
          height: 20px
          display: block
          &:hover
            text-decoration: underline
  .news-more
    position: absolute
    right: 0
    margin: 20px 0
    color: $white
    height: 20px
    vertical-align: middle
    width: 50px
    line-height: 20px
    display: none
    .ico.ipi-bar
      margin: 0
      padding: 0
      color: $white
    ul.more_news_categories
      display: none
    &.open ul.more_news_categories
      padding: 0
      display: block
      position: absolute
      background-color: $brand-dark
      bottom: 40px
      text-align: right
      right: 0
      min-width: 100px
      max-height: 240px
      overflow-y: auto
      margin: 0
      z-index: 2
      li
        border-bottom: 1px solid $sidebar-border
        display: list-item
        padding: 0
        height: auto
        &:hover > a
          text-decoration: underline
        a
          height: auto
          color: $white
          font-weight: 300
          font-size: 0.75rem
          text-transform: uppercase
          white-space: nowrap
          padding: 5px 10px

.widget-news
  .header-s,
  .header-l,
  .main-news
    background-size: cover
    background-position: top center
  div.header-s
    height: 100px
    background-size: cover
    position: relative
  div.header-l
    height: 170px
    background-size: cover
  a
    display: block
    overflow: hidden
    text-decoration: none
    height: 100%
    &:hover
      text-decoration: none
      color: $brand-dark
      > h5, h6
        text-decoration: none
        color: $brand-red
  .news-overlay
    display: block
    position: relative
    width: 100%
    height: 100%
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0,rgba(0,0,0,0.8) 100%)
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 )

    .carousel-control-prev,.carousel-control-next
      display: block
      position: absolute
      height: 100px
      width: 50px
      line-height: 50px
      font-size: 0
      cursor: pointer
      z-index: 1
      background: rgba(0, 0, 0, .5)
      color: $white
      top: 120px
      transform: translate(0, -50%)
      padding: 0
      border: none
      outline: none
      &:before,&:after
        font-size: 5rem
        line-height: 70px
    //override news slider arrows design
    .carousel-control-prev
      border-bottom-right-radius: 100px
      border-top-right-radius: 100px
      left: 0
      text-align: left
      &:before
        content: "\2039"
    .carousel-control-next
      border-bottom-left-radius: 100px
      border-top-left-radius: 100px
      right: 0
      text-align: right
      &:before
        content: "\203A"

  .h6, .h5
    font-weight: 600
    font-size: 0.9rem
    margin: 0
    overflow: hidden
    display: block
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    text-overflow: ellipsis
    line-height: 18px
    height: 39px
    padding-top: 3px
  .h5
    height: auto
    max-height: 43px
    line-height: 20px
    font-size: 0.9rem
  p
    font-size: .8 * $font-size-base
    color: $gray-900
    font-weight: normal
    text-transform: none
    margin: 3px 0 0
    line-height: 16px
    height: 80px
    overflow: hidden
    display: block
    display: -webkit-box
    -webkit-line-clamp: 4
    -webkit-box-orient: vertical
    text-overflow: ellipsis
    span
      text-transform: uppercase
  .video-time
    margin-top: 0
    margin-right: 0
    padding: 0 4px
    font-size: $font-size-small
    background-color: #000
    color: #fff!important
    height: 14px
    line-height: 14px
    opacity: .75
    filter: alpha(opacity=75)
    display: -moz-inline-stack
    vertical-align: top
    display: inline-block
    position: absolute
    right: 2px
    bottom: 2px

//Fix for news text headers. Fix font padding on Windows
.win
  .widget-news
    h5
      padding: 0 0 3px 0
    h6
      padding: 0

//modal stuff @todo move all news modal related sass from main.sass to here

//responsive instagram embed
.insta-container
  position: relative
  padding-bottom: 130%
  height: 0
  overflow: hidden
  clear: both
.insta-frame
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

#news_relevant
  .news_relevant_item
    padding-bottom: 20px
    .news_relevant_item_link
      display: flex
      height: 100%
      width: 100%
      .news_relevant_item_title
        margin-top: 0
        font-size: 1rem
      .news_relevant_item_img
        width: 100%

.relevant-news
  .btn
    float: right

#modal-news
  overflow: hidden
  .modal-body
    flex: none
  .modal-dialog
    .modal-header
      .ipi-close
        order: 1
        margin-left: auto
      .news-logo
        width: 100%
        overflow: hidden
      .news-date
        white-space: nowrap

//JS news stuff
#news-container
  font-size: 0.875rem
  h3
    font-size: 1.5rem
