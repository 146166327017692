@import "tb/core_colors"
@import "bs/custom-grid"
@import "bs/project-bootstrap-variables"
@import "bs/override"

@import "tb-shared/inx_bootstrap-variables"
@import "tb-shared/mixins"
// Original Bootstrap variables
@import "bootstrap/scss/functions"

// Custom variables (overwriting bootstrap variables)
@import "tb/core_variables"
@import "bs/custom-grid"

@import "bootstrap/scss/variables"
//@import "bootstrap/scss/variables-dark"
@import "bootstrap/scss/maps"
// Original Bootstrap mixins
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/utilities"
@import "bootstrap/scss/grid"
//@import "bootstrap/scss/buttons"
//@import "bootstrap/scss/forms"
//only login input fields needed
@import "bootstrap/scss/forms/form-control"
//@import "bootstrap/scss/carousel"
//// Custom variables (overwriting bootstrap variables)
//@import "tb/core_variables"


#sub-header
  //box-shadow: 0 8px 7px -7px rgba(0, 0, 0, 0.2)
  margin-left: -7px
  margin-right: -7px
  padding-right: 7px !important


//create only few needed xxxl classes to avoid adding 6000 lines of css
@media (min-width: 1920px)
  .d-xxxl-block
    display: block !important
  .d-xxxl-none
    display: none !important
//commenting out these 2 avoids adding 6000 lines of css
//@import "bootstrap/scss/helpers"
//@import "bootstrap/scss/utilities/api"

// Custom mixins
@import "tb/core_mixins"
@import "./new/site_variables"
@import "new/login-block"

//=== Media queries for default style
.df
  @import "tb-media-queries/inx_main"
  @import "./new/media_queries"
  //@import "bootstrap/scss/responsive-utilities"
  // Custom grid
  @import "bs/custom-grid"
  @import "tb/core_grid"

//=== Overwrite variables, import mixins and media queries for sidebanner ads
.sb
  @import "./new/sidebanner_variables"
  // Custom mixins
  @import "tb/core_mixins"
  // Core CSS
  @import "bs/custom-grid"
  @import "bootstrap/scss/grid"
  @import "bootstrap/scss/utilities"
  //@import "bootstrap/scss/responsive-utilities"
  // Custom grid
  @import "tb/core_grid"
  @import "tb/core_mixins"
  @import "tb-media-queries/inx_main"
  @import "./new/media_queries"
  @import "tb-media-queries/_mq_index.sass"

//=== Overwrite variables, import mixins and media queries for sidebanner ads, will replace sb class
.sbv2
  @import "./new/sidebanner_variables_v2"
  // Custom mixins
  @import "tb/core_mixins"
  // Core CSS
  @import "bs/custom-grid"
  @import "bootstrap/scss/grid"
  @import "bootstrap/scss/utilities"
  //@import "bootstrap/scss/responsive-utilities"
  // Custom grid
  @import "tb/core_grid"
  @import "tb/core_mixins"
  @import "tb-media-queries/inx_main"
  @import "./new/media_queries"
  @import "tb-media-queries/_mq_index.sass"
//LG and up
@media (min-width: $screen-lg-min)
  .sbv2
    .col-lg-6
      width: 50% !important

@media (min-width: $screen-xs)
  .sbv2
    .col-6
      width: 50% !important

@media (min-width: $screen-sm) and (max-width: $screen-lg)
  .sbv2
    #header
      #header-product-list > ul > li
            &:nth-child(3),&:nth-child(4),&:nth-child(5)
              display: none
      &.with-upper-toolbar //with-upper-toolbar is added by banner system if #upper_toolbar banner is shown
          #header-product-list
            ul
              display: none !important

@media (min-width: $screen-sm) and (max-width: $screen-md)
  .sbv2
    .col-site-ad
      position: relative

@media (min-width: $screen-md) and (max-width: $screen-xl)
  .sbv2
    .col-site-ad
      position: absolute
      right: 0

@media (min-width: 768px) and (max-width: $screen-md)
    .sbv2
      .small_news
        display: block !important

    #news_relevant
      &.row
        .col-md-6
          width: 50% !important

@media (min-width: $screen-xxl-min) // and (max-width: $screen-xxxl-min)
  .sbv2
    .carousel-inner
      flex: 0 0 auto
      width: 100%

//=== Default styles
html
  position: relative
  height: 100%

body
  min-width: $container-xs
  overflow-x: hidden
  display: flex
  flex-direction: column
  height: 100%

a
  color: $brand-dark
  cursor: pointer
  &:hover
    color: $brand-red

label
  font-weight: normal
  line-height: $input-height-base

ul
  list-style: none

.container
  position: relative
  background-color: $body-bg !important
  flex: 1 0 auto

.clear
  clear: both

.btn-secondary,
.btn-primary
  border: 0
  border-bottom: 1px solid $btn-border-bottom
  height: 36px //IE button height fix
  &:hover
    border-bottom: 1px solid $btn-border-bottom

.inline-block
  display: inline-block
  vertical-align: middle

//===Banner styles
.block-2
  margin: 0 auto 10px
  width: $col-adv
  position: relative
  a
    margin-bottom: $grid-gutter-height

#center-block
  #page_middle
    margin-bottom: $grid-gutter-height

.nav-namedays
  display: -webkit-box
  -webkit-box-orient: vertical
  -webkit-line-clamp: 2
  max-width: 140px
  position: relative
  overflow: hidden
  p
    position: relative
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden

#carousel_slider_top
  .carousel-indicators
    position: relative
    bottom: 10px
    button
      border: 0
      display: inline-block
      background: transparent
      outline: none
      font-size: 0px
      cursor: pointer
      padding: 5px 2px
      margin: 0
      text-indent: 0
      width: 10px
      height: 10px
      &:after
        content: ''
        display: inline-block
        line-height: 5px
        height: 5px
        width: 5px
        background: $dark-gray
        border-radius: 50%
      &.active
        &:after
          background: $brand-red

.wrap-carousel
  overflow: hidden
  max-height: 620px
  //margin-bottom: 10px
  //&.slick-initialized
  //  max-height: 640px
  //  margin-bottom: 0

  .main_news_wrapper
    padding: 0

.wrap-carousel-bottom
  overflow: hidden
  max-height: 700px
  margin-bottom: 10px

// Fix for bootstrap bug on dropdowns when position absolute is used
.dropdown-backdrop
  position: relative

// === Main Content
#content-main
  font-size: 0.875rem
  position: relative

  // Weather & name days block for mobile devices
  .col-today
    display: flex
    justify-content: space-around
    align-items: center
    margin: (2 * $grid-gutter-width) 0
    text-align: center
    .col-weather
      min-height: 42px
//if width more than 700 display:none as this if for mobile only
@media screen and (min-width: 700px)
  #content-main
    .col-today
      display: none


.reg-banner
  border: 2px solid $brand-red
  height: $main-block-height
  background-image: url(../img/reg-banner.png)
  background-position: -90px 0
  background-repeat: no-repeat
  padding: 36px 40px 30px 188px
  h2,
  h3
    font-weight: bold
    margin: 0
    text-transform: uppercase
    span
      color: $brand-red
  h2
    font-size: 32px
    white-space: nowrap
  .why-reg
    margin-bottom: 5px
    padding: 15px 0
    li
      line-height: 32px
    .ico
      color: $brand-red
      font-size: 28px
  .btn-primary
    font-weight: bold

.mailplus-banner
  height: $main-block-height
  background-image: url(../img/mailplus-banner.jpg)
  background-repeat: no-repeat
  background-size: cover
  background-position: left bottom
  padding: 25px 0 30px 188px
  color: $brand-dark
  text-align: center
  h3
    margin: 0 0 10px
    font-weight: 100
    border-bottom: 1px solid $brand-gray
    text-transform: uppercase
    font-size: $font-size-base + 7
    text-align: left
  ul
    position: relative
    text-align: left
    li
      text-transform: uppercase
      font-weight: 300
      padding: 10px 0
      line-height: 12px
      min-height: 35px
      span
        font-size: $font-size-base - 2
        position: absolute
        max-width: 80%
      .ico
        font-size: 32px
        left: 0

a.red-link
  font-weight: bold
  font-size: $font-size-base
  color: $brand-red
  text-decoration: underline
  cursor: pointer
  text-transform: none

.img-hover
  padding: 50px 0
  text-align: center
  height: 158px
  background-repeat: no-repeat
  background-size: cover
  background-position: 50%
  &:hover,
  &:focus
    a
      display: inline-block
      background-color: $brand-dark
      width: 58px
      height: 58px
      border-radius: 50%
      line-height: 55px
      text-align: center
      color: $white
      opacity: $opacity-default
      &:hover
        opacity: $opacity-on-hover
        text-decoration: none
      span
        font-size: ($font-size-base + 10)
        text-decoration: none
        opacity: $opacity-default
  span
    opacity: 0
    display: block
    height: 100%
    top: 20px
    position: relative

//Widgets
.widget-1-1,
.widget-1-2,
.widget-1-3,
.widget-1-4,
.widget-1-5,
.widget-1-6,
.widget-1-7,
.widget-2-1,
.widget-2-2,
.widget-2-3,
.widget-2-4,
.widget-footer
  margin-bottom: $grid-gutter-height

.widget-1-2,
.widget-1-3,
.widget-1-4,
.widget-1-5,
.widget-1-6,
.widget-1-7
  height: 145px

.widget-1-1,
.widget-2-1,
.widget-2-2,
.widget-2-3,
.widget-2-4
  height: $main-block-height

.widget-header
  margin-bottom: 2px
  line-height: $widget-header
  height: $widget-header
  border-top: 2px solid $brand-gray
  font-size: ($font-size-base + 4)
  font-weight: bold
  text-transform: uppercase
  a
    color: $black
    &:hover
      color: $brand-red
      text-decoration: none
  .widget-right
    float: right
    height: $widget-header
    width: 250px
    text-align: right
    line-height: 45px
    white-space: nowrap
    overflow: hidden
    padding-right: $grid-gutter-width
    text-overflow: ellipsis
    color: $brand-red
    a
      color: $brand-red
      &:hover
        text-decoration: underline
  .header-info
    font-weight: normal
    color: $brand-dark
    border-left: 1px solid $brand-gray
    padding-left: 10px
    margin-left: 7px
  .ico-product
    padding: 0
    color: $brand-red

.widget-footer
  ul
    padding-left: 30px
    li
      line-height: 24px
      a
        text-decoration: underline
  .ico-product
    padding: 0
    color: $brand-red
    width: 26px

div.widget-descr
  background-image: none
  height: 42px
  line-height: 14px
  padding: 5px 0
  a
    font-weight: bold
    text-decoration: underline
  p
    font-size: ($font-size-base - 2)
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

// Columns
.col-login, .col-widget-1, .col-widget-2
  //float: left
  position: relative
  min-height: 1px
  padding-left: 5px
  padding-right: 5px

.col-login
  padding: 0

.col-widget-2
  display: none

#content-center
  .col-widget-4
    float: right

.col-site-ad
  float: right
  height: $col-adv-height
  margin-bottom: $grid-gutter-height
  //at least needed for sbv2
  width: 400px !important // $col-adv

// NEW style notifications
.col-login
  @import './blocks/notifications'

@import './blocks/meteo'

@import "./new/news"

//===Modal dialog
#modal-news
  .modal
    padding-right: 0 !important

  .modal-dialog
    margin: 0 auto 0 50%
    height: 100%
    #article_foot
      clear: both
    .modal-header
      //position: fixed
      background-color: $white
      z-index: 9
      top: 0
      right: 1px
      height: 80px
      transform: translateZ(2px)
      width: 100%

      a
        text-decoration: underline
        float: right
        clear: both
        &:hover
          color: $brand-red
      .news-logo,
      .modal-logo
        display: inline-block
        position: relative
        height: 100%
        img
          max-width: 100%
          max-height: 50px
          position: absolute
          top: 0
          bottom: 0
          margin: auto
      .modal-logo
        img
          max-width: initial
      .news-date
        //padding-top: 15px
        display: inline-block
        text-align: right
        float: right
        color: $brand-dark
        span
          float: right
          &:first-child
            float: none
        a
          white-space: nowrap
    .ipi-close
      padding: 15px
      font-size: 24px
      opacity: $opacity-on-hover
      color: $brand-dark
      outline: none
      line-height: 24px
      &:hover
        color: darken($brand-dark, 5%)
    .modal-content
      border: 0
      height: calc(100% - 80px) //header height
      padding: 0 10%
      overflow-y: scroll
      .modal-body
        padding: 20px 10px 10px
        .news-pic-wrap
          width: 40%
          float: left
          margin: 7px 15px 7px 0
        .news-pic
          width: 100%
          background-repeat: no-repeat
          background-position: left
          display: block
        .news-text
          ul
            clear: both
            list-style: disc
        .img-copyright,
        .news-copyright
          color: $dark-gray
          font-size: ($font-size-base - 3)
        .news-copyright
          text-align: right
          margin-top: -7px
          margin-bottom: 10px
        a
          text-decoration: none
        h1
          color: $brand-dark
          font-weight: bold
          font-size: 1.5rem
        p
          font-size: 1.05rem
        ul
          list-style-type: circle
          font-size: 1.1rem
          padding: 15px 30px
          background: $brand-gray
          display: inline-block
        .list-group
          background-color: transparent
      .btn-primary
        font-weight: 300
        white-space: normal
        height: auto
        span
          white-space: nowrap
    .modal-footer
      text-align: left
      clear: both
      border: none
    .modal-banner
      text-align: center
      padding: 10px 0
      img
        width: 100%
        max-width: 500px

.legend
  border-top: 1px solid $brand-gray
  margin: 30px 0 0
  line-height: 25px
  span
    font-size: 1.2rem
    color: $gray-text
    position: relative
    display: inline-block
    height: 20px
    left: 7px
    top: -14px
    padding: 0 7px
    background-color: $modal-content-bg

//Fix footer for portal
#footer
  padding: 0
  position: static
  .footer-wrapper
    flex-wrap: initial
    margin-right: auto
    margin-left: auto
    padding-left: $grid-gutter-width
    padding-right: $grid-gutter-width

@import "./modules/preloader"

// Firefox clear cache page
.ff-clear-cache
  text-align: center

  img
    width: 100%
    border: 1px solid $brand-gray

  @media screen and (min-width: 1261px)
    img
      width: inherit

.blaze-slider
  --slides-to-show: 1
