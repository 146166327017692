//******************************************************************************
  * Overwriting breakpoint variables for sidebanner ads
  ******************************************************************************

// XS - Extra small screen / phone
$screen-xs:          300px
$screen-xs-min:      $screen-xs
$screen-phone:       $screen-xs-min

// MS - Mid-Small breakpoint
$screen-ms:          480px
$screen-ms-min:      $screen-ms
$screen-ms-max:      ($screen-sm-min - 1)

// SM - Small screen / tablet
$screen-sm:          960px
$screen-sm-min:      $screen-sm
$screen-tablet:      $screen-sm-min

// MD - Medium screen / desktop
$screen-md:          1260px
$screen-md-min:      $screen-md
$screen-desktop:     $screen-md-min

// LG - Large screen / wide desktop
$screen-lg:          1700px
$screen-lg-min:      $screen-lg
$screen-lg-desktop:  $screen-lg-min

// XL - Extra large screen
$screen-xl:          1920px
$screen-xl-min:      $screen-xl
$screen-xl-desktop:  $screen-xl-min

// XXL - Extra-extra large screen
$screen-xxl:         2560px
$screen-xxl-min:     $screen-xxl
$screen-xxl-desktop: $screen-xxl-min

// Redefined max value
$screen-xs-max-new:  $screen-ms-min - 1
$screen-xs-max:      $screen-xs-max-new
$screen-ms-max:      $screen-sm-min - 1
$screen-sm-max:      $screen-md-min - 1
$screen-md-max:      $screen-lg-min - 1
$screen-lg-max:      $screen-xl-min - 1
$screen-xl-max:      $screen-xxl-min - 1

$container-xl:       1430px
