//******************************************************************************
  * Spinning loading animation
  ******************************************************************************
.sk-circle
  height: 40px
  margin: 100px auto
  position: relative
  width: 40px
  animation: 250ms fadeIn
  .sk-child
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%
    &:before
      background-color: $brand-dark
      border-radius: 100%
      content: ''
      display: block
      height: 15%
      margin: 0 auto
      width: 15%
      animation: sk-circleBounceDelay 1.2s infinite ease-in-out both

  @for $c from 2 through 12
    $deg: #{($c - 1) * 30}deg
    $time: -#{1.2 - calc(($c - 1) / 10)}s
    .sk-circle#{$c}
      -webkit-transform: rotate($deg)
      -ms-transform: rotate($deg)
      transform: rotate($deg)
      &:before
        -webkit-animation-delay: $time
        animation-delay: $time

@keyframes sk-circleBounceDelay
  0%, 80%, 100%
    transform: scale(0)
  40%
    transform: scale(1)

@keyframes fadeIn
  0%
    opacity: 0
    margin: 0
    height: 0
  100%
    opacity: 1
    margin: 100px auto
    height: 40px
