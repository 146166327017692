//******************************************************************************
//* Meteo block
//******************************************************************************

.inx-weather
  align-items: center
  //display: inline-flex
  font-size: 0.875rem
  &:hover
    +tdn
  .weather
    &__city
      font-weight: bold
      text-decoration: underline
    &__info
      display: inline-block

.ico-meteo
  font-size: 36px
  color: $brand-red
  padding: 0 5px
  min-width: 46px
  text-decoration: none
