//=== XS (x<480px)
+xs
  margin-bottom: $footer-height + 127

  .block-2
    width: 100%

  .wrap-carousel
    overflow: hidden
    max-height: 785px

    //&.slick-initialized
    //  max-height: none
    //  padding-bottom: 5px

  //.slick-arrow
  //  transition: all 1s cubic-bezier(0.20, 0.60, 0.40, 1)
  //  opacity: .8

  #carousel_slider_top
    .wrap-carousel
      min-height: 640px
      margin-bottom: calc($grid-gutter-height / 2)

    //bigger dots for touch devices so they are easier to click
    .carousel-indicators
      button
        padding: 12px
        width: 12px
        height: 12px
        &:after
          line-height: 8px
          height: 8px
          width: 8px

  .col-site-ad
    width: 100%
    height: auto

  .widget-news
    .readmore
      display: none

  .login-block
    position: static

  .login-block,
  .logged-in
    height: auto

    //.register
    // padding: 10px 15px
    // height: $widget-header - 15

    .login
      height: $login-input-height + 30

      .form-group
        display: block
        margin-right: $grid-gutter-width
        width: calc(100% - $grid-gutter-width)

      .recovery
        margin: 0

        a
          padding-left: 0
          font-size: 0.875rem

      label
        line-height: 20px
        margin: 0
        height: auto
        display: block

      .default-login
        border-spacing: 0

      .vertical-line
        top: 20px

      .wrap-or
        top: 57px

      .bsp
        border-spacing: 0

    &:before
      border-width: 12px
      left: 59px
      top: -22px

    .login-bottom-block
      height: auto

      a
        padding: 0

        p
          text-align: left
          width: 100%
          margin-top: -10px
          padding-left: 35px
          font-size: 0.875rem

      .ico-product
        padding-left: 0

      &.go_domain_or_premium_user
        strong
          font-size: 1.1rem

    .mail-notification
      position: relative

      p
        font-size: 0.875rem

    .incoming
      .incoming-footer
        line-height: inherit
        padding: 5px 10px

        label
          padding: 5px 0
          float: none
          text-align: left

  .col-carousel
    clear: both

  .widget-header
    .widget-right
      width: 100px
      overflow: hidden
  #modal-news
    .modal-dialog
      margin: 0 auto
      transform: initial!important

      .modal-header
        //width: calc(100% - 2px)

        .modal-logo
          img
            max-width: 200px

        .news-date
          position: absolute
          right: 0
          bottom: 0
          font-size: 0.875rem
          padding: 0 10px 3px 0

      .modal-content
        padding: 0
        top: 78px
        position: fixed

        .modal-body
          padding: 0 10px

          .news-pic-wrap
            height: auto
            width: 100%

          p:first-of-type
            padding-left: 0
            width: 100%

  .footer-wrapper
    flex-direction: column
    width: $container-xs

  .social-login
    margin-top: -4.6 * $grid-gutter-width

//=== MS (480-719px)
+ms
  margin-bottom: $footer-height + 110
  #header
    .col-weather
      +dn

  #sub-header
    margin: 0
    padding: 0.75rem $padding-screen-ms

  .wrap-carousel
    max-height: 785px

    &.slick-initialized
      overflow: hidden
      max-height: none
      padding-bottom: 5px

  //.slick-arrow
  //  transition: all 1s cubic-bezier(0.20, 0.60, 0.40, 1)
  //  opacity: .5

  #carousel_slider_top
    .wrap-carousel
      min-height: 640px
      margin-bottom: calc($grid-gutter-height / 2)

  .widget-news
    .readmore
      display: inline-block !important

  .col-site-ad
    width: 100% !important

  #content-main,
  #content-center,
  #social
    padding-left: $padding-screen-ms
    padding-right: $padding-screen-ms

  .login-block,
  .logged-in
    height: auto

    &:before
      border-width: 10px
      left: 65px
      top: -17px

    .login-bottom-block
      height: auto

  .col-carousel
    clear: both
    .small_news
      padding: 0

  #modal-news
    .modal-dialog
      margin: 0 auto
      width: 100%
      max-width: 100%

      .modal-header
        width: calc(100% - 2px)

      .modal-content
        padding: 0 0 150px 0
        top: 78px
        position: fixed

        .modal-body
          padding: 0 10px

          .news-pic-wrap
            height: auto
            width: 100%

            .news-pic
              width: auto

  .footer-wrapper
    flex-direction: column
    width: $container-ms
    .footer-logo
      text-align: left
      order: 0
      margin-bottom: 20px
      margin-top: 10px
    .footer-menu
      margin: 0

//=== SM (720-959px)
+sm

  #sub-header
    padding:
      bottom: 1rem
      top: 1rem

  .wrap-carousel
    overflow: hidden
    max-height: 320px

  .slick-arrow
    transition: all 1s cubic-bezier(0.20, 0.60, 0.40, 1)
    opacity: .5

  .col-login
    width: 300px

  .login-block,
  .logged-in
    //.register
    // padding: 10px 15px
    // height: $widget-header - 15

    .login
      height: $login-input-height + 30

      .form-group
        display: block
        margin-right: $grid-gutter-width
        width: calc(100% - $grid-gutter-width)

      .recovery
        margin: 0

        a
          padding-left: 0
          font-size: 0.875rem

      label
        line-height: 20px
        margin: 0
        display: block
        height: auto

      .default-login
        border-spacing: 0

      .vertical-line
        top: 20px

      .wrap-or
        top: 57px

      .bsp
        border-spacing: 0

    &:before
      left: 60px
      top: -25px

    .login-bottom-block
      &.go_domain_or_premium_user
        p
          font-size: 0.875rem

        strong
          font-size: 1.1rem

      a
        padding: 0 20px

        p
          text-align: left
          width: 100%
          margin-top: -10px
          padding-left: 35px
          font-size:  0.875rem

      .ico-product
        padding-left: 0

    .mail-notification
      padding:
        left: $grid-gutter-width
        right: $grid-gutter-width

      p
        font-size: 0.875rem
        padding-right: 3 * $grid-gutter-width

    .incoming
      .incoming-footer
        line-height: inherit
        padding: 5px 10px

        label
          padding: 5px 0
          float: none
          text-align: left

  .login-block
    margin-bottom: $grid-gutter-height

  .col-carousel
    clear: both
    .main_news_wrapper
      width: 100%
    .small_news
      display: none !important
  #modal-news
    .modal-dialog
      width: 80%
      max-width: 80%
      margin: 0 auto 0 20%

  #carousel_slider_top
    .wrap-carousel
      min-height: 325px
      margin-bottom: $grid-gutter-height

  .footer-wrapper
    width: $container-sm
    .footer-copyright
      text-align: right
      margin-left: 4rem

  .social-login
    margin-top: -4.6 * $grid-gutter-width

//temp show 2 products more until logo is moved to header from sub-header
@media (min-width: $screen-md-min) and (max-width: $screen-lg-min)
  #header
    &:not(.with-upper-toolbar) //with-upper-toolbar is added by banner system if #upper_toolbar banner is shown
      #header-product-list > ul > li
            &:nth-child(4),&:nth-child(5)
              display: flex

//HACK! todo add .col-site-ad absolute to 720px-991 SM custom breakpont?
@media (min-width: 720px) and (max-width: 990px)
  .container
    max-width: 950px
  .col-site-ad
    position: absolute
    right: 0
    width: auto



//=== MD (960-1259px)
+md
  .wrap-carousel
    overflow: hidden
    max-height: 320px

  .col-login
    width: $col-login-md
    .main_news_wrapper
      width: 100% !important
    .small_news
      display: none !important

  .login-block,
  .logged-in
    &::before
      left: 65px
      top: -27px

  .login-block
    margin-bottom: $grid-gutter-height

  .col-site-ad
    position: absolute
    right: 0
    width: auto

  .col-carousel
    clear: both
    .main_news_wrapper
      width: 50%
    .small_news
      padding: 0 0 0 $grid-gutter-width
      width: 50%
  #modal-news
    .modal-dialog
      width: 60%
      max-width: 60%
      margin: 0 auto 0 40%

  #carousel_slider_top
    .wrap-carousel
      min-height: 325px
      margin-bottom: $grid-gutter-height

  .footer-wrapper
    width: $container-md

//LG and up
@media (min-width: $screen-lg-min)
  #content-main
    //So top news block do not overflow to bottom news block when no ads are shown
    height: $col-adv-height + 20px

//=== LG (1260-1439px)
@media (min-width: $screen-lg-min)
  .under_login_wrapper
    display: block !important

+lg
  .col-login
    width: $col-login-lg - $grid-gutter-width

  .login-block
    float: left
    // margin-right: $grid-gutter-width
    .block-content
      margin-bottom: $grid-gutter-height

  .col-site-ad
    position: absolute
    right: 0
    width: auto

  .col-widget-1
    width: 410px

  .reg-banner
    padding-left: 150px
    background-position: (-120px) 0

  .col-carousel
    position: absolute
    margin-left: $col-login-lg
    width: $container-lg - $col-login-lg - $col-adv - $grid-gutter-width
    .small_news
      padding: 0

  #carousel_slider_top
    .wrap-carousel
      min-height: 635px
      margin-bottom: $grid-gutter-height

  .footer-wrapper
    width: $container-lg


//XL (1440-1919px)
+xl
  .col-login
    width: $col-login-xl - $grid-gutter-width

  .login-block
    float: left
    width: $col-login-xl - $grid-gutter-height
    .block-content
      margin-bottom: $grid-gutter-height

  .col-site-ad
    position: absolute
    right: 0
    width: 540px !important //$col-adv-xl

  .col-widget-1
    width: 400px

  .col-carousel
    position: absolute
    width: $container-xl - $col-login-xl - $col-adv-xl - $grid-gutter-width
    margin-left: $col-login-xl
    .small_news
      padding: 0

  #carousel_slider_top
    .wrap-carousel
      min-height: 635px
      margin-bottom: $grid-gutter-height

  .footer-wrapper
    width: $container-xl


//XXL (x>1920px)
+xxl
  .col-login
    width: $col-login-xxl

  .login-block
    float: left
    width: $col-login-xxl
    .block-content
      margin-bottom: $grid-gutter-height

  .col-site-ad
    width: 540px !important //$col-adv-xl
    position: absolute
    right: 0

  .col-widget-1,
  .col-widget-2
    width: 425px

  .col-carousel
    position: absolute
    width: $container-xxl - $col-login-xxl - $col-adv-xl - $grid-gutter-width
    margin-left: $col-login-xxl + $grid-gutter-width
    .big_news, .small_news
      padding: 0
    .big_news
      margin-left: $grid-gutter-width

  .modal-contents
    padding: 20px 20%

    .ipi-close
      left: 10%

  #carousel_slider_top
    .wrap-carousel
      min-height: 635px
      margin-bottom: $grid-gutter-height

  .footer-wrapper
    width: $container-xxl
